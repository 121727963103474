@use "src/theme" as *;

.textarea {
  @include flexColumn();
  margin-top: 28px;
  font-weight: 900;
  text-align: left;

  .label {
    font-weight: 500;
  }
}

textarea {
  box-sizing: border-box;
  color: $dark;
  font-family: $fontFamily;
  font-size: $fontBody;
  margin-top: 4px;
  padding: 8px;
  width: 100%;
  border: $secondary solid 2px;
  resize: none;
  &:focus,
  &:active {
    outline-color: $link;
  }
}
