@use "src/theme" as *;

.button {
  @include flexRow(center, center, nowrap);
  background-color: $light;
  border: 2px solid $primary;
  color: $dark;
  cursor: pointer;
  height: 44px;
  font-family: $fontFamily;
  font-size: $fontLabel;
  font-weight: 900;
  margin-bottom: 80px;
  min-width: 140px;
  outline: none;
  padding: 12px 16px;
  transition: all ease-in-out 0.2s;
  white-space: nowrap;

  &:hover,
  &:focus {
    border-color: $primaryHover;
  }

  &:disabled {
    background-color: $gray;
    border-color: $dark;
    cursor: default;
  }
}
