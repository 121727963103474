@font-face {
  font-family: "EnterpriseSans";
  font-weight: 500;
  font-display: swap;
  src: local("myfont"),
    url("../assets/EnterpriseSans-Regular.woff2") format("woff");
}

@font-face {
  font-family: "EnterpriseSans";
  font-weight: 700;
  font-display: swap;
  src: local("myfont"),
    url("../assets/EnterpriseSans-Bold.woff2") format("woff");
}

//App Shell
.app {
  background-color: white;
  display: flex;
  max-width: 100vw;
  cursor: default;
}

//Body
body {
  color: #2a2c34;
  margin: 0 auto;
  padding: 0;
  font-family: "EnterpriseSans", "Arial", sans-serif;
  font-size: 14px;
  // image-rendering: optimizeQuality;
  line-height: 18px;
  max-width: 1440px;
  min-width: 320px;
  @media screen and (min-width: 1440px) {
    box-shadow: 1px 0 #d0d0ce, -1px 0 #d0d0ce;
    background-color: #efefee;
  }
}

//Global classes
h1,
h2,
h3,
p {
  margin: 0;
}

p {
  color: #2a2c34;
}

a {
  text-decoration: none;
  cursor: default;

  &:focus-visible {
    outline: 1px rgba(black, 0.4) dashed;
  }
}

img {
  flex-shrink: 0;
}
