@use "src/theme" as *;

.root {
  @include flexColumn(center, center);
  background-color: $footerBGColor;
  min-height: 72px;
  position: absolute;
  width: 100%;

  .footer {
    @include flexColumn($alignY: center);
    max-width: 1440px;
    padding: 0 108px;
    width: 100%;
    @media screen and (max-width: 1024px) {
      padding: 24px;
      position: static;
    }
    @media screen and (max-width: 550px) {
      padding: 16px;
    }

    p {
      color: $footerColor;
      width: 100%;
    }
  }

  .alternative {
    padding-right: 38%;
    position: static;
    @media screen and (max-width: 1024px) {
      padding-right: 24px;
    }
    @media screen and (max-width: 550px) {
      padding-right: 16px;
    }
  }
}
