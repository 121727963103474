@use "src/theme" as *;

.forms {
  @include flexColumn($alignX: center);
  background-color: #f2f0e3;
  margin: 0 auto;
  padding: 60px 24px 0;
  position: relative;
  text-align: center;
  width: 100%;
  height: 100%;

  .item {
    margin-top: 40px;
  }

  button {
    margin: 40px auto 0;
  }

  form {
    max-width: 700px;
  }
}

.modal {
  height: auto;
  padding-left: 52px;
  padding-right: 52px;
  padding-bottom: 52px;
  max-width: 632px;
  width: 100%;
  @media screen and (max-width: 620px) {
    padding: 20px 20px 52px;
    word-wrap: break-word;
  }

  .check svg {
    margin-top: 40px;
    height: 52px;
  }
}
