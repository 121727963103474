@use "src/theme" as *;

.root {
  @include flexColumn(center, center);
  background-color: $secondary;
  width: 100%;
  height: 100%;
  padding: 60px 28px 60px 20px;
  @media screen and (max-width: 768px) {
    padding: 60px 12px;
  }
}

.resourceGuide {
  @include flexColumn();
  max-width: 880px;
  white-space: pre-wrap;
  width: calc(100% - 20px);
  @media screen and (max-width: 768px) {
    width: 100%;
  }

  p {
    width: 100%;
  }

  img {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  .source {
    margin: 16px 0;
    &:first-child {
      margin-top: 0;
    }

    span {
      color: $dark;
      text-decoration: underline;
    }

    sup {
      font-size: 10px;
    }
  }
}
