@use "src/theme" as *;

.support {
  @include flexColumn($alignX: center);
  margin: 0 auto;
  padding-top: 40px;
  position: relative;
  width: 100%;

  .bg {
    bottom: 0;
    height: 72vh;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: -1;
    object-fit: cover;
  }
}

.text {
  @include flexColumn(center, center);
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 12px;
  margin: 20px auto 60px;
  max-width: 840px;
  text-align: center;
  width: 100%;
  z-index: 1;
  @media screen and (max-width: 599px) {
    width: calc(100% - 40px);
  }

  p {
    margin-top: 12px;
    white-space: pre-line;
    width: 100%;
  }
}
