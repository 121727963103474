@use "src/theme" as *;

.outcare {
  @include flex();
  background-color: $outcare;
  position: relative;
  width: 100%;

  .bg {
    margin-top: -201px;
    max-height: 460px;
    width: 100%;
    @media screen and (max-width: 1201px) {
      margin-top: -90px;
      max-height: 260px;
    }
    @media screen and (max-width: 800px) {
      margin-top: -40px;
      max-height: 180px;
    }
    @media screen and (max-width: 650px) {
      margin-top: -1px;
      max-height: 88px;
    }
  }

  .info {
    @include flexRow($alignX: center);
    background: linear-gradient(180deg, white 285px, transparent 31%);
    padding: 20px 6px 0;
    width: 100%;
    z-index: 1;
    @media screen and (max-width: 1201px) {
      background: linear-gradient(
        180deg,
        white calc(100% - 89px),
        transparent 31%
      );
      padding: 60px 12px 0;
    }
    @media screen and (max-width: 800px) {
      background: linear-gradient(
        180deg,
        white calc(100% - 39px),
        transparent 31%
      );
    }
    @media screen and (max-width: 650px) {
      background: $light;
    }
    a {
      font-weight: 700;
    }

    .left {
      @include flexColumn();
      line-height: 24px;
      margin-left: 40px;
      margin-top: 40px;
      max-width: 400px;
      @media screen and (max-width: 800px) {
        align-items: center;
        margin: 0;
        text-align: center;
        white-space: normal;
      }

      img {
        image-rendering: -webkit-optimize-contrast;
        margin-bottom: 12px;
        margin-left: -20px;
        width: auto;
        @media screen and (max-width: 650px) {
          height: auto;
          max-width: 280px;
          width: 100%;
        }
      }
    }

    .right {
      margin-left: 60px;
      max-width: 600px;
      position: relative;
      text-align: center;
      width: 100%;
      @media screen and (max-width: 1200px) {
        margin-left: 0;
        margin-bottom: 24px;
      }

      .img {
        width: auto;
        @media screen and (max-width: 800px) {
          height: auto;
          margin: 12px 0 20px;
          width: calc(100% - 24px);
        }
      }

      .label {
        line-height: 24px;
        @media screen and (max-width: 650px) {
          white-space: normal;
        }
      }
    }
  }

  .container {
    box-sizing: border-box;
    height: 40px;
    margin: 0 auto;
    padding-bottom: 152px;
    position: relative;
    width: 100%;
    @media screen and (max-width: 1201px) {
      height: auto;
      padding-bottom: 0;
      margin-left: 20px;
      width: auto;
    }
  }

  .services {
    @include flexColumn();
    bottom: 8px;
    left: 120px;
    position: absolute;
    @media screen and (max-width: 1330px) {
      left: 40px;
    }
    @media screen and (max-width: 1201px) {
      position: static;
      margin-top: -180px;
      width: 100%;
    }
    @media screen and (max-width: 800px) {
      margin: -80px 0 20px;
      padding-right: 16px;
    }
    @media screen and (max-width: 650px) {
      margin-top: -32px;
    }
  }

  .service {
    @include flexColumn(center, center);
    color: $light;
    height: 84px;
    margin-bottom: 12px;
    padding-left: 96px;
    z-index: 2;
    position: relative;
    @media screen and (max-width: 400px) {
      height: 112px;
    }

    img {
      height: 84px;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;
      position: absolute;
      width: auto;
    }

    a {
      color: $light;
      &:hover,
      &:focus {
        color: $dark;
      }
    }

    .serviceTitle {
      margin-bottom: 4px;
    }
  }
}
