@use "src/theme" as *;

.worksheet {
  @include flexColumn($alignX: center);
  margin: 0 auto;
  position: relative;
  width: 100%;
}

.top {
  @include flexColumn($alignX: center);
  background: linear-gradient(180deg, $light 50%, $worksheetTop 50%);
  width: 100%;
  @media screen and (max-width: 699px) {
    background: linear-gradient(360deg, $worksheetTop 58%, $light 40%);
  }
  @media screen and (max-width: 570px) {
    background: linear-gradient(360deg, $worksheetTop 70%, $light 32%);
  }

  .content {
    @include flexColumn();
    padding: 48px 24px;
    position: relative;
    max-width: 1280px;
    width: 100%;
    @media screen and (max-width: 570px) {
      padding-bottom: 20px;
    }

    .bg {
      bottom: 0;
      object-fit: contain;
      position: absolute;
      right: 24px;
      @media screen and (max-width: 1023px) {
        height: auto;
        max-width: 380px;
      }
      @media screen and (max-width: 699px) {
        display: none;
      }
    }

    .title {
      @include flexRow($wrap: nowrap, $alignY: flex-end);
      position: relative;
      padding-bottom: 16px;
      width: 100%;
      @media screen and (max-width: 570px) {
        flex-wrap: wrap;
        padding-bottom: 0;
      }

      .inner {
        @include flexColumn();

        p {
          line-height: 24px;
          margin: 12px 0;
          max-width: 700px;
          width: 100%;
          @media screen and (max-width: 570px) {
            max-width: unset;
          }
        }
      }

      .bgMobile {
        display: none;
        max-width: 220px;
        top: 44px;
        @media screen and (max-width: 699px) {
          display: block;
          height: auto;
        }
        @media screen and (max-width: 570px) {
          top: unset;
          margin: 0 auto;
        }
      }

      .hr {
        position: absolute;
        bottom: -20px;
        background-color: $worksheetTop;
        height: 40px;
        width: 100vw;
        left: -24px;
        display: none;
        @media screen and (max-width: 699px) and (min-width: 570px) {
          display: block;
        }
      }
    }

    .items {
      @include flexRow($alignY: stretch);
      margin-top: 20px;
      z-index: 2;
      width: 100%;
      @media screen and (max-width: 699px) {
        justify-content: space-between;
      }
      @media screen and (max-width: 570px) {
        margin-top: 0;
      }

      .item {
        @include flexColumn();
        background-color: $light;
        max-width: 312px;
        position: relative;
        transition: all 0.2s ease-in-out;
        width: 100%;
        @media screen and (max-width: 1023px) {
          width: calc(60% / 2);
        }
        @media screen and (max-width: 699px) {
          margin-bottom: 20px;
          width: calc(50% - 20px);
        }
        @media screen and (max-width: 570px) {
          margin-bottom: 40px;
          width: 100%;
          max-width: unset;
        }

        &:first-child {
          margin-right: 20px;
          @media screen and (max-width: 570px) {
            margin-right: 0;
          }
        }

        img {
          object-fit: cover;
          width: 100%;
          @media screen and (max-width: 1023px) {
            height: 100px;
          }
        }

        .text {
          @include flexColumn($alignY: space-between);
          padding: 24px 16px;
          height: 100%;

          .upper {
            @include flexColumn();
            label {
              margin: 12px 0;
            }
          }

          a {
            font-weight: 700;
          }
        }
      }
    }
  }
}

.reverse {
  background: linear-gradient(180deg, $light 44%, $nonbinary 40%);
  @media screen and (max-width: 699px) {
    background: linear-gradient(360deg, $nonbinary 70%, $light 40%);
  }
  @media screen and (max-width: 570px) {
    background: linear-gradient(360deg, $nonbinary 78%, $light 32%);
  }

  .content {
    padding-bottom: 16px;
    @media screen and (max-width: 570px) {
      padding-bottom: 32px;
    }

    .bg {
      bottom: -48px;
      left: 0;
      right: unset;
      @media screen and (max-width: 1023px) {
        height: 580px;
        max-width: 500px;
        width: 60%;
        object-fit: cover;
      }
      @media screen and (max-width: 699px) {
        display: none;
      }
    }

    .title {
      .hr {
        background-color: $nonbinary;
      }

      .bgMobile {
        @media screen and (max-width: 570px) {
          width: 300px;
          max-width: unset;
        }
      }

      .inner p {
        max-width: 640px;
      }
    }

    .textContent {
      align-self: flex-end;
      @media screen and (max-width: 1023px) {
        width: 64%;
      }
      @media screen and (max-width: 699px) {
        width: 100%;
      }
    }

    .items {
      .item {
        margin-bottom: 120px;
        @media screen and (max-width: 1023px) {
          width: calc(50% - 20px);
        }
        @media screen and (max-width: 570px) {
          width: 100%;
          margin-bottom: 160px;
          max-width: unset;

          img {
            object-position: top;
          }
        }
      }
    }

    .bottomText {
      @include flexColumn();
      background-color: rgba(#c0d4b3, 0.9);
      align-self: center;
      bottom: -120px;
      margin-top: 20px;
      padding: 12px 0;
      position: absolute;
      text-align: center;
      white-space: pre-line;
      width: 100%;

      label {
        color: $link;
        margin: -8px auto 0;
      }
    }

    .caption {
      align-self: center;
      text-align: center;
      // width: 100%;
      display: block;
      margin: 0 auto;
      font-size: $fontLabel;
    }
  }
}

.bottom {
  @include flexColumn($alignX: center);
  background-color: $worksheetBottom;
  width: 100%;

  .content {
    @include flexRow($alignY: center, $alignX: space-between);
    padding: 48px 24px 60px;
    max-width: 1200px;
    width: 100%;
    @media screen and (max-width: 650px) {
      justify-content: center;
    }
  }

  .logo {
    @include flexColumn($alignX: center);
    text-align: center;
    width: 50%;
    max-width: 480px;
    @media screen and (max-width: 1023px) {
      width: 40%;

      img {
        width: 100%;
        height: auto;
      }
    }
    @media screen and (max-width: 650px) {
      margin-bottom: 24px;
      width: 100%;
    }
  }

  a {
    font-weight: 700;
    margin-top: 20px;
  }

  .stats {
    background-color: $light;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    cursor: pointer;
    margin-left: 12px;
    width: calc((50% - 24px) / 2);
    &:hover {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
    }
    @media screen and (max-width: 1023px) {
      width: calc((60% - 24px) / 2);
    }
    @media screen and (max-width: 650px) {
      width: calc((100% - 24px) / 2);
    }

    img {
      width: 100%;
      max-width: 312px;
      height: auto;
    }

    @media screen and (max-width: 499px) {
      margin-bottom: 20px;
      width: 100%;

      img {
        image-rendering: optimizeQuality;
        max-width: unset;
      }
    }
  }
}
