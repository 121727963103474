//Color Palette
$primary: var(--palette-primary, #e66d29);
$primaryHover: var(--palette-primary-hover, #d95206);
$secondary: var(--palette-secondary, #8155a2);
$light: var(--palette-light, #ffffff);
$dark: var(--palette-dark, #2a2c34);
$gray: var(--palette-gray, #e4e4e4);
$link: var(--palette-link, #1a75b5);
$linkHover: var(--palette-link-hover, #00396c);

//Defaults
$buttonBGColor: $primary;
$buttonColor: $light;
$buttonHover: $primaryHover;
$shadow: rgba(0, 0, 0, 35%);

//Components
$headerBGColor: var(--header-bg-color, $secondary);
$headerColor: var(--header-color, $light);

$footerBGColor: var(--footer-bg-color, $dark);
$footerColor: var(--footer-border, $light);
$outcare: var(--outcare, #429ac0);
$nonbinary: var(--nonbinary, #c0d4b3);
$worksheetTop: var(--worksheet-top, #ffd93f);
$worksheetBottom: var(--worksheet-bottom, #f2f0e3);
$printBG: var(--print-bg, #dde4ff);
