@use "src/theme" as *;

.worksheet {
  @include flexColumn($alignX: center);
  margin: 0 auto;
  padding: 60px 24px 0;
  position: relative;
  text-align: center;
  width: 100%;

  .bg {
    bottom: 0;
    height: 80vh;
    left: 0;
    object-fit: cover;
    position: absolute;
    width: 100%;
    z-index: -1;
  }

  p {
    line-height: 24px;
    max-width: 520px;
    margin-bottom: 16px;
  }

  a {
    font-weight: 900;
    &:hover {
      .download svg {
        fill: $linkHover;
      }
    }
  }

  .download svg {
    fill: $link;
    height: 16px;
    width: 16px;
  }

  .fax svg {
    fill: $dark;
    height: 28px;
    width: 24px;
  }

  .questions {
    @include flexRow($alignX: space-between);
    margin: 20px 0 56px;
    max-width: 840px;
    width: 100%;

    .item {
      max-width: 380px;
      width: calc(50% - 12px);
      @media screen and (max-width: 650px) {
        max-width: unset;
        width: 100%;
      }
    }
  }

  .none {
    display: none;
  }
}

//PRINT STYLE
@page {
  size: letter portrait;
  // size: auto;
  margin: 0;
}

.print {
  font-size: $fontHeading5;
  padding: 40px;
  position: relative;

  img {
    height: auto;
    position: absolute;
    right: 40px;
    top: 40px;
    width: 260px;
  }

  svg {
    align-self: flex-start;
    width: 100px;
    margin-bottom: 12px;
  }

  .hr {
    background-color: $secondary;
    height: 12px;
    margin: 80px 0 40px;
    opacity: 0.7;
    width: 100%;
  }

  .body {
    @include flexColumn($alignX: center);
    max-width: 780px;
    width: 100%;
  }

  .questions {
    margin-bottom: 20px;

    .item {
      @include flexColumn();
      font-weight: 700;
      margin-bottom: 20px;
      width: calc(50% - 20px);

      label {
        margin-bottom: 8px;
      }
    }
  }

  .box {
    background-color: $printBG;
    box-sizing: border-box;
    color: $dark;
    font-family: $fontFamily;
    font-size: $fontBody;
    height: 108px;
    padding: 8px;
    text-align: left;
    width: 100%;
  }

  .footer {
    font-size: $fontLabel;
    text-align: left;
  }
}
